<template>
  <div class="box box--dashboardBlock">
    <div class="box__inner">
      <div
        class="dashboardBlock"
        :class="'dashboardBlock--' + dashboardType"
      >
        <DashboardSidebar :type="dashboardType"/>
        <div class="dashboardBlock__content">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DashboardSidebar from '@/components/DashboardSidebar/DashboardSidebar'
  import './DashboardContainer.styl'
  import '@/views/dashboard/flyer/BookingList/BookingList.styl'

  export default {
    props: ['dashboardType'],
    name: 'DashboardContainer',
    components: {
      DashboardSidebar,
    },
    metaInfo: {
      meta: [{
        name: 'robots', content: 'noindex',
      }],
    },
  }
</script>
