<template>
  <div class="dashboardBlock__sidebar js-customScroll">
    <vue-scroll :ops="ops">
      <div class="dashboardBlock__nav">
        <span
          v-for="item of sidebar"
          :key="item.text"
          class="dashboardBlock__nav-item"
        >
          <router-link
            v-if="item.comingSoon ? false : true"
            class="dashboardBlock__nav-link"
            :to="item.href"
            active-class="dashboardBlock__nav-link--active"
            >
            <span v-if="item.icon" class="dashboardBlock__nav-icon">
              <SvgIcon :name="item.icon"/>
            </span>
            <span class="dashboardBlock__nav-text" v-html="item.text"></span>
          </router-link>
          <span
            v-if="item.comingSoon ? true : false"
            class="dashboardBlock__nav-link"
            active-class="dashboardBlock__nav-link--active"
            exact
            @click="$modal.show('ComingSoonPopup')"
          >
            <span v-if="item.icon" class="dashboardBlock__nav-icon">
              <SvgIcon :name="item.icon"/>
            </span>
            <span class="dashboardBlock__nav-text" v-html="item.text"></span>
          </span>
          <div v-if="item.sub" class="dashboardBlock__nav-sub">
            <router-link
              class="dashboardBlock__nav-sub-item"
              :to="subitem.href"
              active-class="dashboardBlock__nav-sub-item--active"
              exact
              v-for="(subitem, index) of item.sub"
              :key="index"
            >
              Aircraft {{subitem.text}}
            </router-link>
            <router-link
              v-if="item.newAircraft"
              class="dashboardBlock__nav-sub-item"
              :to="item.newAircraft.href"
              active-class="dashboardBlock__nav-sub-item--active"
              exact
            >
              {{item.newAircraft.text}}
            </router-link>
          </div>
        </span>
      </div>
      <span
        class="dashboardBlock__sidebar-link"
        v-if="type === 'flyer'"
        v-html="'Switch from <b>FLYER</b> to <b>AIRCRAFT PROVIDER</b> login'"
        @click="$modal.show('ComingSoonPopup')"
      ></span>
      <span
        class="dashboardBlock__sidebar-link"
        v-else-if="type === 'provider'"
        v-html="'Switch from <b>aircraft provider</b> to <b>flyer</b> login'"
        @click="$modal.show('ComingSoonPopup')"
      ></span>
    </vue-scroll>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['type'],
    name: 'DashboardSidebar',
    data: () => ({
      flyer: [{
        text: 'Dashboard',
        href: '/dashboard',
      }, {
        text: 'Profile',
        icon: 'user2',
        href: '/dashboard/profile',
      }, {
        text: 'Members',
        icon: 'members',
        href: '/dashboard/members',
      }, {
        text: 'Fly<b>Calendar</b>',
        icon: 'flycal',
        href: '/dashboard/flycalendar',
      }, {
        text: 'Subscriptions',
        icon: 'subscriptions',
        href: '/dashboard/subscriptions',
      }, {
        text: 'Route alerts',
        icon: 'alarm',
        href: '/dashboard/alerts',
      }, {
        text: 'Auctions (bids)',
        icon: 'auction',
        href: '/dashboard/auctions',
      }, {
        text: 'FLY EMPTY',
        icon: 'discuss',
        href: '/dashboard/requests',
      }, {
        text: 'Sell spaces',
        icon: 'ticket',
        href: '/dashboard/seats',
        comingSoon: true,
      }, {
        text: 'Manual Requests',
        icon: 'calendar-aircraft',
        href: '/dashboard/manual-request',
      }, {
        text: 'Settings & security',
        icon: 'locked',
        href: '/dashboard/settings',
      }, {
        text: 'FLY<b>Rewards</b>',
        icon: 'giftbox',
        href: '/dashboard/flyrewards',
        comingSoon: false,
      }, {
        text: 'Payment information',
        icon: 'wallet',
        href: '/dashboard/payment',
        comingSoon: false,
      }, {
        text: 'Confirmed Bookings',
        icon: 'ticket',
        href: '/dashboard/booking',
        comingSoon: true,
      }, {
        text: 'Flights<br><i>upcoming and historical</i>',
        icon: 'distance',
        href: '/dashboard/flights',
        comingSoon: true,
      }, {
        text: 'Messages',
        icon: 'message',
        href: '/dashboard/messages',
        comingSoon: true,
      }, {
        text: 'Passenger identification',
        icon: 'card',
        href: '/dashboard/passenger-id',
        comingSoon: true,
      }],
      provider: [
       {
         text: 'Dashboard',
         href: '/provider-dashboard',
       },
       {
         text: 'Profile',
         icon: 'user2',
         href: '/provider-dashboard/profile',
       },
       {
         text: 'Settings & security',
         icon: 'locked',
         href: '/provider-dashboard/settings',
       },
        {
         text: 'Team',
         icon: 'pilot-hat',
         href: '/provider-dashboard/personnel',
         comingSoon: true,
       },
       {
         text: 'Fleet',
         icon: 'plane-provider',
         href: '/provider-dashboard/fleet',
         // sub: [],
         // newAircraft: {
           // text: 'new aircraft',
           // href: '/provider-dashboard/new-aircraft'
         // }
       },
        {
         text: 'Automated Scheduler',
         icon: 'calendar-board',
         href: '/provider-dashboard/automated-scheduler',
       },
       {
        text: 'Charter availability uploads',
        icon: 'calendar-aircraft',
        href: '/provider-dashboard/charter-uploads',
      }, {
        text: 'Flight availability uploads',
        icon: 'calendar-checked',
        href: '/provider-dashboard/flight-uploads',
      },
      //   {
      //   text: 'Reservations',
      //   icon: 'ticket',
      //   href: '/provider-dashboard/booking',
      // },
      //  {
      //   text: 'Bookings',
      //   icon: 'ticket',
      //   href: '/provider-dashboard/booking-confirmations',
      // },
      //  {
      //   text: 'Flights',
      //   icon: 'distance',
      //   href: '/provider-dashboard/flights',
      // },
      // {
      //   text: 'FLY<b>Rewards</b>',
      //   icon: 'giftbox',
      //   href: '/provider-dashboard/flyrewards',
      //   comingSoon: false,
      // },
      {
        text: 'Payment and bank info',
        icon: 'wallet',
         href: '/provider-dashboard/payment',
      },
      {
        text: 'Messages',
        icon: 'message',
        href: '/provider-dashboard/messages',
        comingSoon: true,
      },
      // {
      //   text: 'Requests',
      //   icon: 'discuss',
      //   href: '/provider-dashboard/requests',
      // },
      {
        text: 'Auctions bids and offers',
        icon: 'auction',
        href: '/provider-dashboard/auctions',
       },
      ],
      admin: [
        {
          text: 'Dashboard',
          href: '/admin',
        },
        {
          text: 'Profile',
          icon: 'user2',
          href: '/admin/profile',
        },
        {
        text: 'Settings & security',
        icon: 'locked',
        href: '/admin/settings',
        },
        {
          text: 'Fly empty',
          icon: 'user2',
          href: '/admin/request-empty',
        },
        {
          text: 'Network activity',
          icon: 'network',
          href: '/admin/network',
        },
        {
          text: 'Aircraft provider requests',
          icon: 'discuss',
          href: '/admin/provider-sign-ups',
        },
        {
          text: 'Manual Requests',
          icon: 'calendar-aircraft',
          href: '/admin/requests',
        },
        {
          text: 'Offset Purchases',
          icon: 'wallet',
          href: '/admin/offsets',
        },
        {
          text: 'Flights: <i>current, historical and upcoming</i>',
          icon: 'distance',
          href: '/admin/flights',
          comingSoon: true,
        },
        {
          text: 'Auctions: <i>current and historical</i>',
          icon: 'auction',
          href: '/admin/auction',
          // comingSoon: true,
        },
        {
          text: 'Route Alerts',
          icon: 'ticket',
          href: '/admin/alerts',
        },
        {
          text: 'Featured Flights',
          icon: 'ticket',
          href: '/admin/featured',
        },
        {
          text: 'Reservations',
          icon: 'ticket',
          href: '/admin/reservations',
        },
        {
          text: 'Confirmed Reservations',
          icon: 'ticket',
          href: '/admin/confirmed-reservations',
        },
        {
          text: 'Bookings',
          icon: 'ticket',
          href: '/admin/bookings',
        },
        {
          text: 'Confirmed Bookings',
          icon: 'ticket',
          href: '/admin/confirmed-bookings',
        },
        {
          text: 'Flycal Requests',
          icon: 'ticket',
          href: '/admin/flycal-requests',
        },
        // {
        //   text: 'Blog',
        //   icon: 'discuss',
        //   href: '/admin/blog',
        // },
        {
          text: 'Confirm manual booking',
          icon: 'checked-circle',
          href: '/admin/booking-confirmations',
          comingSoon: true,
        },
        {
          text: 'Live seat requests',
          icon: 'armchair',
          href: '/admin/seat-request',
          comingSoon: true,
        },
        // {
        //   text: 'Aircraft uploads',
        //   icon: 'plane-provider',
        //   href: '/admin/aircraft-uploads',
        //   sub: [],
        //   newAircraft: {
        //     text: 'new aircraft',
        //     href: '/admin/new-aircraft'
        //   }
        // },
        // {
        //   text: 'Charter uploads',
        //   icon: 'world',
        //   href: '/admin/charter-uploads'
        // },
        // {
        //   text: 'Flight uploads',
        //   icon: 'aeroplane2',
        //   href: '/admin/flight-uploads'
        // },
        {
          text: 'FLY<b>Rewards</b>',
          icon: 'giftbox',
          href: '/admin/flyrewards',
        },
        {
          text: 'Sell, legs, seats',
          icon: 'wallet',
          href: '/admin/seats',
          comingSoon: true,
        },
        {
          text: 'Messages',
          icon: 'message',
          href: '/admin/messages',
          comingSoon: true,
        },
        {
          text: 'Argus statistic',
          icon: 'message',
          href: '/admin/argus-statistic',
        },
        {
          text: 'TFR sign ups',
          icon: 'message',
          href: '/admin/tfr-sign-ups',
        },
        {
          text: 'Get all feed',
          icon: 'message',
          href: '/admin/get-all-feed',
        },
        {
          text: 'Newsletter generator',
          icon: 'message',
          href: '/admin/newsletter-generator',
        },
        {
          text: 'Remove unsubscribed',
          icon: 'message',
          href: '/admin/remove-unsubscribed',
        },
        {
          text: 'Promo code generator',
          icon: 'message',
          href: '/admin/promo-codes-generator',
        },
        {
          text: 'Users subscriptions',
          icon: 'message',
          href: '/admin/users-subscriptions',
        },
        {
          text: 'Gifts generator',
          icon: 'message',
          href: '/admin/gifts-generator',
        },
        {
          text: 'Subscription analytics',
          icon: 'message',
          href: '/admin/subscription-analytics',
        },
      ],
      report: [
        {
          text: 'Front page',
          icon: 'edit',
          href: '/fly-report/articles',
        },
      ],
      ops: {
        rail: {
          size: '5px',
        },
        bar: {
          size: '5px',
          background: 'rgba(244, 244, 244, 0.2)',
        },
      },
    }),
    computed: {
      sidebar () {
        if (this.type === 'flyer') {
          return this.flyer
        } else if (this.type === 'provider') {
          const fleet = this.$store.state.fleet.list.map(item => {
            return {
              text: item.value,
              href: '/provider-dashboard/fleet/' + item.id,
            }
          })

          const provider = this.provider.map(item => {
            if (item.sub) {
              return {
                ...item,
                sub: fleet,
              }
            }
            return item
          })
          return provider
        } else if (this.type === 'report') {
          const { subcategories = [] } = this.$store.state.blogDashboard

          return [
            ...this.report,
            ...subcategories.map((item) => ({
              text: item.attributes.name,
              href: `/fly-report/articles?subcategory=${item.id}`,
              icon: item.attributes.icon || 'question',
            })),
          ]
        } else {
          const aircraftUploads = this.$store.state.fleet.list.map(item => {
            return {
              text: item.value,
              href: '/admin/aircraft-uploads/' + item.id,
            }
          })

          const admin = this.admin.map(item => {
            if (item.sub) {
              return {
                ...item,
                sub: aircraftUploads,
              }
            }
            return item
          })
          return admin
        }
      },
    },
    watch: {
      '$route' (to, from) {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          document.querySelector('.page').classList.remove('dashboardMenuOpen')
          document.querySelector('body').style.touchAction = 'auto'
          document.querySelector('.content').classList.remove('noScroll')
        }
      },
    },
    methods: {
      closeSidebar (route) {
        this.$router.push(route)
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
